.blog-title1 {
    margin: 0 0 1.5em 0 !important;
}

.blog-title-meta {
    margin-top: 1em;
    font-size: 0.4em;
    font-style: italic;
    color: grey;
}

.blog-paragraph {
    font-size: 1.05rem;
    line-height: 2rem;
    margin-bottom: 2rem !important;
}

.blog-list {
    font-size: 1.05rem;
    line-height: 2rem;
    margin: 1rem 0 1rem 1rem !important;
}

.blog-list li {
    margin-bottom: 1rem !important;
}

.blog-divider {
    color: #C62828 !important;
    font-size: 3em !important;
    border-color: rgba(200, 200, 200, 1) !important;
    margin-bottom: 1.5em !important;
}

.blog-cta {
    margin: 5rem auto 6rem auto;
    padding: 0.2rem 1rem;
    font-size: 1.6rem;
	line-height: 2.2rem;
	font-weight: 500;
    height: 100%;
    white-space: normal;
}

.blog-footer {
    margin-top: 2rem;
    font-size: 1.1em
}


/* Large devices (desktops, 992px and below) */
@media only screen and (max-width: 992px) {
    .blog-cta {
        margin: 3rem auto;
        font-size: 1.4rem;
        line-height: 2rem;
    }
}