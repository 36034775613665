.items-container {
	text-align: left;
}

.title-number {
	color: #F05454;
	float: left;
	font-size: 5.6em;
	font-weight: 700;
	padding-right: 0.3em;
	line-height: 1em;
}

.item-title {
	margin-bottom: 0.9em !important;
}

/* RESPONSIVE **************************************************************************************************************************************/

/* MacBook Pro 16inch and below */
@media only screen and (max-width: 1792px) {

}

/* MacBook Pro 14inch and below */
@media only screen and (max-width: 1520px) {

}

/* XX-Large devices (larger desktops, MacBook Air, 1400px and below) */
@media only screen and (max-width: 1440px) {

}

/* Large tablets */
@media only screen and (max-width: 1210px) {
}

/* X-Large devices (large desktops, 1200px and below) */
@media only screen and (max-width: 1200px) {}

/* Large devices (desktops, 992px and below) */
@media only screen and (max-width: 992px) {

	.item {
		margin-bottom: 1rem;
	}

	.title-number {
		float: none;
		margin-top: 0.2em;
	}

	.item-title {
		margin: 0.2em 0 0.7em 0 !important;
	}
}

/* Medium devices (tablets, 768px and below) */
@media only screen and (max-width: 768px) {}