.logo-nyse {
    width: 100px;
}

.logo-nasdaq {
    width: 120px;
}

.logo-tmx {
    width: 75px;
}

.logo-cse {
    width: 75px;
}

/* RESPONSIVE **************************************************************************************************************************************/

/* MacBook Pro 16inch and below */
@media only screen and (max-width: 1792px) {}

/* MacBook Pro 14inch and below */
@media only screen and (max-width: 1520px) {}

/* XX-Large devices (larger desktops, MacBook Air, 1400px and below) */
@media only screen and (max-width: 1440px) {}

/* Large tablets */
@media only screen and (max-width: 1210px) {}

/* X-Large devices (large desktops, 1200px and below) */
@media only screen and (max-width: 1200px) {}

/* Large devices (desktops, 992px and below) */
@media only screen and (max-width: 992px) {

    .logo-nyse {
        width: 80px;
    }

    .logo-nasdaq {
        width: 100px;
    }

    .logo-tmx {
        width: 60px;
    }

    .logo-cse {
        width: 60px;
    }
}

/* Medium devices (tablets, 768px and below) */
@media only screen and (max-width: 768px) {}