.landing-content {
	overflow: hidden;
	max-width: 1300px;
	margin: auto;
	padding: 0;
	text-align: center;
}

.landing-content img,
.landing-content video {
	border-radius: 5px;
}

.img-background {
	margin: 2em;
	/* box-shadow: 0 0 16px 0px rgba(0,0,0,0.1); */
	background-color: #FFF;
	text-align: center;
}

.text-container {
	text-align: left;
}

.page {
	padding: 8em 0;
	height: auto;
}

.page-feature {
	padding-bottom: 5em;
}

.page-title {
	margin: 0.3em 0 2.1em 0px;
	text-align: center;
	font-size: 4em;
	font-weight: 500;
}

.page-title-feature {
	margin-top: 0;
	padding-bottom: 1em;
	border-bottom: 1px solid rgb(190, 191, 193);
	text-align: left;
}

.page-description {
	color: rgb(94, 97, 102);
	display: block;
	font-size: 1.1em;
}

video {
	width: 100%;
}

.landing-signup {
	margin-top: 1.5em;
	font-weight: 500;
}


/* RESPONSIVE **************************************************************************************************************************************/

/* MacBook Pro 16inch and below */
@media only screen and (max-width: 1792px) {}

/* MacBook Pro 14inch and below */
@media only screen and (max-width: 1520px) {

	.landing-content {
		padding: 0 40px;
	}
}

/* XX-Large devices (larger desktops, MacBook Air, 1400px and below) */
@media only screen and (max-width: 1440px) {

	.page-title {
		font-size: 3.5em;
		font-weight: 500;
	}
}

/* X-Large devices (large desktops, 1200px and below) */
@media only screen and (max-width: 1200px) {}

/* Large devices (desktops, 992px and below) */
@media only screen and (max-width: 992px) {

	.landing-content {
		padding: 0 25px;
	}

	.img-background {
		margin: 1em 0 0 0;
	}

	.page {
		padding: 2em 0;
	}

	.text-container {
		margin: auto;
	}

	.page-title {
		font-size: 2.9em;
		font-weight: 600;
		margin: 1em 0 1.2em 0;
		padding: 0 0.4em;
	}

	.page-title-feature {
		margin-top: 0.5em;
	}
}

/* Medium devices (tablets, 768px and below) */
@media only screen and (max-width: 768px) {}