@import '../../common.scss';

.container {
	margin-top: 75px;	/* Header height */
	padding: 13vh 0 17vh 10rem;
	text-align: center;
	// background: linear-gradient(0deg, rgba(232, 232, 232, 0.6), white);
}

.content {
	margin: 0 auto;
	overflow: hidden;
	max-width: 2260px;
}

.block-left-content {
	margin: 0 auto;
	padding-left: 13px;
	max-width: 38rem;
	text-align: left;
}

#candle {
	width: 1200px;
}

.title {
	text-align: left;
	margin: 0 0 3rem 0;
	color: #000;
	font-size: 4.6em;
	font-weight: 450;
}

.page-description {
	margin: 1rem auto 0 auto;
	font-size: 1.4em;
	color: $text-grey;
}

.signup {
	font-size: 1rem;
	line-height: 1rem;
	font-weight: 500;
}

.assets-list {
	margin-top: 4.2rem;
	font-size: 3.5em;
	font-weight: 400;
	color: grey;
}

.carousel {
	max-width: 38rem;
	margin: 4rem auto 0 auto;	/* Bottom 0 for mobile */
	cursor: pointer;
}

.card-meta {
	text-align: left;
	font-size: 1.1rem;
	padding: 0.5em;
}

.card-metric {
	display: inline-block;
	font-weight: bold;
	width: 2.8em;
	text-align: right;
	margin-right: 0.3em;
}


/* RESPONSIVE **************************************************************************************************************************************/

/* MacBook Pro 16inch and below */
@media only screen and (max-width: 1792px) {

	.container {
		padding: 12vh 0 13vh 10rem;
	}
}

/* MacBook Pro 14inch and below */
@media only screen and (max-width: 1520px) {

	.container {
		padding-top: 9vh;
	}

	.block-left-content, .carousel {
		max-width: 36rem;
	}

	.title {
		font-size: 4.3em;
	}

	.page-description {
		font-size: 1.3em;
	}

	.assets-list {
		margin-top: 3.5rem;
		font-size: 3.2em;
	}

	#candle {
		width: 68rem;
	}

	.card-meta {
		padding: 0.45em;
		font-size: 1rem;
	}
}

/* XX-Large devices (larger desktops, MacBook Air, 1400px and below) */
@media only screen and (max-width: 1440px) {

	.container {
		padding-top: 8vh;
	}

	.block-left-content, .carousel {
		max-width: 33rem;
	}

	.title {
		font-size: 3.9em;
		margin-bottom: 2rem;
	}

	.page-description {
		font-size: 1.2em;
	}

	.signup {
		font-size: 0.98rem;
	}

	.assets-list {
		margin-top: 2.9rem;
		font-size: 2.9em;
	}

	.carousel {
		margin-top: 2em;
	}

	.card-meta {
		font-size: 0.98rem;
		padding: 0.2em;
	}

	#candle {
		width: 58rem;
	}
}

/* Large tablets */
@media only screen and (max-width: 1210px) {
	
	.container {
		padding-left: 5rem;
	}

    .title {
		font-size: 3.4em;
		margin-bottom: 2rem;
	}

	.page-description {
		font-size: 1.12em;
	}

	.signup {
		font-size: 0.95rem;
	}

	.assets-list {
		margin-top: 3rem;
		font-size: 2.4em;
	}

	.carousel {
		margin-top: 3em;
	}

	.card-meta {
		font-size: 0.92rem;
		padding: 0.1em;
	}

	#candle {
		width: 58rem;
	}
}

/* X-Large devices (large desktops, 1200px and below) */
@media only screen and (max-width: 1200px) {}

/* Large devices (desktops, 992px and below) */
@media only screen and (max-width: 992px) {

	.container {
		padding: 3rem 0 3.5rem 0;
	}

	.content {
		margin: 0 25px;
		text-align: center;
	}

	.block-left-content {
		margin: auto;
		padding-left: 0px;
		width: 100%;
		text-align: center;
	}

	.title {
		font-size: 3.2em;
		font-weight: 550;
		text-align: center;
		margin-bottom: 3.5rem;
	}

	.page-description {
		width: 20rem;
		font-size: 1.2em;
		color: grey;
	}

	.assets-list {
		margin-top: 1.2rem;
		font-size: 1.6em;
	}

	.carousel {
		margin-top: 2.8em;
		max-width: 17rem;
		text-align: left;
	}

	.card-meta {
		font-size: 0.96rem;
		padding: 0em;
	}

	#candle {
		margin-top: 1rem;
		width: 100%;
	}

	.signup {
		margin-top: 2rem;
	}
}

/* Medium devices (tablets, 768px and below) */
@media only screen and (max-width: 768px) {}