.value-title {
	margin-top: 2em;
	text-Align: left;
	color: #fff;
	font-size: 4em;
}

.value-signup {
	margin: 3em 0 4em;
	font-size: 2.2em;
	line-height: 1.6em;
	font-weight: 600;
	height: 100%;
}


/* RESPONSIVE **************************************************************************************************************************************/

/* MacBook Pro 16inch and below */
@media only screen and (max-width: 1792px) {

}

/* MacBook Pro 14inch and below */
@media only screen and (max-width: 1520px) {

	.value-title {
		font-size: 4.1em;
	}
}

/* XX-Large devices (larger desktops, MacBook Air, 1400px and below) */
@media only screen and (max-width: 1440px) {

	.value-title {
		font-size: 3.6em;
	}
}

/* Large tablets */
@media only screen and (max-width: 1210px) {
}

/* X-Large devices (large desktops, 1200px and below) */
@media only screen and (max-width: 1200px) {}

/* Large devices (desktops, 992px and below) */
@media only screen and (max-width: 992px) {

	.value-title {
		margin-top: 11vh;
		font-size: 2.4em;
		text-align: center;
	}

	.value-signup {
		margin-top: 2em;
		font-size: 1.3em;
	}
}

/* Medium devices (tablets, 768px and below) */
@media only screen and (max-width: 768px) {}